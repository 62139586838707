import { Inject, Injectable, Injector } from "@angular/core";
import { HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { HttpApiService } from "src/app/core/services/api/http-api.service";
import { UserService } from "src/app/core/services/user/user.service";
import jwt_decode from "jwt-decode";
import { tap, catchError } from "rxjs/operators";
import { Observable, of, throwError } from "rxjs";
import { RegisterUser } from "src/app/core/models/registerUser";
import { Router } from "@angular/router";
import { MessageService } from "src/app/core/services/message.service";
import { ConnectorUser } from "src/app/core/models/connectorUser";
import { APP_CONFIG, AppConfig } from 'src/app/core/config/app-config';
import { EdcApiService } from 'src/app/core/services/api/edc-api.service';

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(
    private api: HttpApiService,
    private userService: UserService,
    private messageService: MessageService,
    private injector: Injector,
    private router: Router,
    @Inject(APP_CONFIG) private config: AppConfig,
  ) {}

  login(username: string, password: string): Observable<any> {
    const requestData = { username, password };
    console.info("User trying to login");
    return this.api.post(`/login`, requestData).pipe(
      tap((data) => {
        this.setLocalStorage(data);
        this.setUserData();
        console.info("Successful login");
      })
    );
  }

  // Keycloak direct login (no-backend)
  loginKeycloak(username: string, password: string): Observable<any> {
    let keycloakUrl = this.config.keycloakUrl;
    let keycloakRealm = this.config.keycloakRealm;
    let clientId = this.config.clientId; // "connector-dashboard"

    // Up to Keycloak 16
    // let endpoint = `${keycloakUrl}/auth/realms/${keycloakRealm}/protocol/openid-connect/token`
    // Keycloak 17+
    let endpoint = `${keycloakUrl}/realms/${keycloakRealm}/protocol/openid-connect/token`
    console.log('keycloak endpoint =', endpoint)

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    let body = new URLSearchParams();
    body.set("username", username)
    body.set("password", password)
    body.set("grant_type", "password")
    body.set("client_id", clientId)

    const requestData = { username: username, password: password, grant_type: "password", client_id: clientId };
    console.info("User trying to login with Keycloak");
    return this.api.post(endpoint, body.toString(), { headers }).pipe(
      tap((data) => {
        this.setLocalStorage(data);
        this.setUserData();
        // Use injector instead of initializing edcApiService in the constructor
        // to avoid the circular dependency between EdcApiService and AuthService
        let edcApiService = this.injector.get<EdcApiService>(EdcApiService)
        edcApiService.setEdcClient()
        console.info("Successful login");
      }),
      catchError((error: HttpErrorResponse): Observable<any> => {
        let message;
        switch (error.status) {
          case   0:
            message = "Unknown error"
            break;
          case 401:
            message = "Wrong credentials"
            break;
          case 500:
            message = "Internal server error"
            break;
          default:
            console.log(error)
            return throwError(() => error);
        }
        this.messageService.messageNotifier$.next([{
          text: message,
          type: "error",
        }]);
        return of(null);
      })
    );
  }

  logout(): void {
    console.info("Logging out");
    localStorage.clear();
    this.userService.setUser(null as any);
    this.router.navigateByUrl("/auth");
    this.messageService.messageNotifier$.next([{
      text: "Logged out",
      type: "success",
    }]);
  }

  setUserData(logout = false): void {
    const token = localStorage.getItem("token");
    if (token) {
      const data: any = jwt_decode(token);
      const user: ConnectorUser = {
        id: data.sub,
        demo: data.demo,
        email: data.email,
        username: data.preferred_username,
      };

      this.userService.setUser(user);
      return;
    }
    const user: ConnectorUser = {
      id: undefined,
      demo: undefined,
      email: undefined,
      username: undefined,
    };

    this.userService.setUser(user);
  }


  private setLocalStorage(data: any): void {
    localStorage.setItem("token", data.access_token);
    localStorage.setItem("refresh_token", data.refresh_token);
    localStorage.setItem(
      "expires_in",
      String(Date.now() + data.expires_in * 1000)
    );
    localStorage.setItem(
      "refresh_expires_in",
      String(Date.now() + data.refresh_expires_in * 1000)
    );
  }

  public setAuthenticatedUser() {
    this.setUserData();
  }

  public isAuthenticated(): boolean {
    const token = localStorage.getItem('token');
    const expires_in = localStorage.getItem('expires_in')
    if (token) {
      if (expires_in) {
        const temp = (expires_in as any - Date.now()) / 1000;
        if (temp > 0) {
          return true;
        }
        else {
          localStorage.clear();
          return false;
        }
      }
    }
    return false;
  }

  public getToken(): string {
    return localStorage.getItem('token') || "";
  }

}
